/**
 * @name: 旅游地址 标签
 * @author: lili
 * @date: 2023-06-01 15:25
 * @description：旅游地址 标签
 * @update: 2023-06-01 15:25
 */
import {get, postJ} from "@/request";
import {
  ITourLocation,
  ITourLocationParam,
  ITourLocationStatus
} from "@/apis/travelManage/address/types";

/**
 * 旅游地址查询
 * @param param 查询参数
 */
export const queryTourLocationListApi = (param: ITourLocationParam) => get("/golf/tourLocation/queryTourLocationList", param)
/**
 * 创建
 * @param data
 */
export const tourLocationCreateApi = (data: ITourLocation) => postJ("/golf/tourLocation/create", data)
/**
 * 修改
 * @param data
 */
export const tourLocationModifyApi = (data: ITourLocation) => postJ("/golf/tourLocation/modify", data)
/**
 * 删除
 * @param id
 */
export const tourLocationRemoveApi = (id: string) => get("/golf/tourLocation/remove/" + id)
/**
 * 查询详情
 * @param id
 */
export const getTourLocationDetailApi = (id: string) => get<ITourLocation>("/golf/tourLocation/detail/" + id)

/**
 * 切换状态
 * @param id,status
 */
export const modifyTourLocationStatusApi = (data: ITourLocationStatus) => postJ("/golf/tourLocation/statusUpdate",data)
