
/**
 * @name: 旅游地址 标签
 * @author: lili
 * @date: 2023-06-01 15:25
 * @description：旅游地址 标签
 * @update: 2023-06-01 15:25
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {ITourLocation, ITourLocationParam, ITourLocationStatus} from "@/apis/travelManage/address/types";
import {
  modifyTourLocationStatusApi,
  queryTourLocationListApi, tourLocationCreateApi,
  tourLocationModifyApi, tourLocationRemoveApi
} from "@/apis/travelManage/address";

@Component({})
export default class SpecificationPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ITourLocation[] = []
  // 表单
  modelForm: Partial<ITourLocation> = {}
  // 查询参数
  queryParam: ITourLocationParam = {
    locationName: "",
    status:''
  }

  // crudOption
  option: ICrudOption = {
    addTitle: '新增地址标签',
    editTitle: '编辑地址标签',
    dialogWidth: '600px',
    column: [
      {
        "label": "ID",
        "prop": "id",
        "align": "left",
        "width": "180",
        addHide: true,
        editHide: true
      },
      {
        "label": "标签名称",
        "prop": "locationName",
        "align": "center",
        "overHidden": true,
        maxlength: 20,
        span: 18,
        rules: [
          {required: true, message: "请输入规格名称", trigger: 'blur'}
        ]
      },
      {
        "label": "绑定地区",
        "prop": "types",
        "align": "center",
        "type": "select",
        span: 18,
        "dicData": [
          {
            label: "海内",
            value: 1
          },
          {
            label: "海外",
            value: 2
          }
        ],
        rules: [
          {required: true, message: "请选择绑定地区", trigger: 'blur'}
        ]
      },
      {
        label: "排序",
        prop: "sort",
        align: 'center',
        span: 18,
        rules: [{
          required: true,
          validator: (rule: any, value: any, callback: any) => {
            if (!value) {
              return callback(new Error("请输入排序"));
            } else if (!/^[1-9]\d{0,4}$/.test(value)) {
              callback(new Error("请输入排序为正整数，最大长度4位数!"));
            } else {
              callback();
            }
          }, trigger: 'blur'
        },
        ],
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "type": "switch",
        "slot": true,
        search: true,
        "dicData": [
          {
            label: "禁用",
            value: 2
          },
          {
            label: "启用",
            value: 1
          }
        ],
        rules: [
          {required: true, message: "请选择状态", trigger: 'blur'}
        ]
      },
    ]
  }
  rowDel (form: ITourLocation, index: number) {
    tourLocationRemoveApi(form.id).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  rowSave (form: ITourLocation, done: Function, loading: Function) {
    tourLocationCreateApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  /**
   * 切换状态
   * @param id id
   * @param status 状态
   */
  handleSwitch (id: string, status: number) {
    modifyTourLocationStatusApi({id, status} as ITourLocationStatus).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  rowEdit (form: ITourLocation, done: Function, loading: Function) {
    tourLocationModifyApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  openEdit (row: ITourLocation, index: number) {
    // @ts-ignore
    this.$refs.crudRef.rowEdit(row, index);
  }

  getList () {
    this.tableLoading = true
    queryTourLocationListApi(this.queryParam).then(e => {
      this.tableLoading = false
      this.tableData = e;
    })
  }

  created () {
    this.getList()
  }
}
